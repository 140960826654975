import React, { useState, useEffect } from "react"
import "./index.scss"
import styled from "styled-components"
import styles from "../../common/button/button.module.scss"
import ModalWindow from "../../../components/common/modal-window/modal-window"
import Button from "../../common/button/button"
import Tree from "react-ui-tree"
import SearchBar from "material-ui-search-bar"
import { MdMoveToInbox } from "react-icons/md"
import {
  getKeywords,
  moveKeyword,
  moveKeywords,
} from "../../../services/keyword"
import _ from "lodash"
import { union } from "lodash"

const MoveKeyword = ({ moveNode, closeHandler, updateKeyword }) => {
  const ID = "ID"
  const [keywords, setKeywords] = useState([])
  const [state, setState] = useState([])
  const [data, setData] = useState([])
  const [deleteKeyword, setDeleteKeyword] = useState(false)
  const [editModal, setEditModal] = useState(null)
  const [searchInput, setSearchInput] = useState("")
  const [filteredResults, setFilteredResults] = useState([])
  const [searched, setSearched] = useState("")
  const [node, setNode] = useState(moveNode)
  const [terms, setTerms] = useState("")
  const [source, setSource] = useState()
  const [destination, setDestination] = useState()
  const [keyword, setKeyword] = useState()
  const [activeNode, setActiveNode] = useState()
  const [modalOpen, setModalOpen] = useState(false)
  const [message, setMessage] = useState()

  const searchItems = searchValue => {
    const keywords = data.children
    if (searchValue !== "") {
      const filteredData = keywords.filter(keyword => {
        return Object.values(keyword)
          .join("")
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      })

      let tree = {
        id: "root",
        module: "Keywords",
        children: filteredData,
      }
      setData(tree)
    } else {
      setData(data)
    }
  }

  const rootKeywords = (keywords) => {
      let parentPathExists = _.filter(keywords, {
        parentPath: "",
      })

      let parentPathMissing = _.filter(keywords, (keyword) => {
        return !_.has(keyword, 'parentPath')
      })

      const filtered = union(parentPathExists, parentPathMissing)

      return filtered
  }

  useEffect(() => {
    getKeywords().then(keywords => {
      setTerms(keywords)
      let newData = []

      const filtered = rootKeywords(keywords)

      // let filtered = _.filter(keywords, {
      //   parentPath: "",
      // })
      _.map(filtered, node => {
        const result = createKeys(node, keywords, moveNode.module)
        newData.push(result)
      })

      let tree = {
        id: "root",
        module: "Keywords",
        children: newData,
      }

      setData(tree)
    })
  }, [])

  const createKeys = (node, keywords, moveNode) => {
    if (moveNode !== node.term) {
      const json = {
        id: node.id,
        module: node.term,
        parentPath: node.parentPath,
        collapsed: true,
        children: [],
        child: node.children,
      }

      let result = ""
      if (node.children) {
        if (node.children !== "") {
          let children = node.children.split(",")
          _.map(children, child => {
            const newChild = _.find(keywords, {
              term: child,
            })
            if (newChild) {
              result = createKeys(newChild, keywords, moveNode)
            }
            if (result !== '') {
              json.children.push(result)
            }  
          })
        }
      }

      return json
    }  
    else {
      return '';
    }  

  }

  const cancelSearch = () => {
    getKeywords().then(keywords => {
      setTerms(keywords)
      let newData = []

      const filtered = rootKeywords(keywords)
      // let filtered = _.filter(keywords, {
      //   parentPath: "",
      // })
      _.map(filtered, node => {
        const result = createKeys(node, keywords)
        newData.push(result)
      })

      let tree = {
        id: "root",
        module: "Keywords",
        children: newData,
      }

      setData(tree)
    })
  }

  const confirmMove = () => {
   setMessage(`Move ${moveNode.module} to ${activeNode.module}`)

   if (!moveNode.parentPath) {
    moveParentNode()
   } else if (moveNode.parentPath === "") {
    moveParentNode()
   } else if (moveNode.children !== "" && activeNode.id === "root") {
      console.log('moveNode',moveNode)
      console.log('activeNode', activeNode)

      const parentPath = moveNode.parentPath

      const index = parentPath.indexOf("=>")
      const path = parentPath
        .substr(index + 2, parentPath.length - 1)
        .split(",")

      const parent = path[1].trim()

      const sourceParent = _.find(terms, {
        term: parent,
      })

      if (sourceParent.term !== activeNode.module) {
        const searchTerm = moveNode.module
        const childArray = sourceParent.children.split(",")
        const SourceParentChildren = _.remove(childArray, child => {
          return child !== searchTerm
        })
        const updatedChildren = SourceParentChildren.join()

        if (activeNode.id === "root") {
          const UpdatedObj = {
            source: {
              id: sourceParent.id,
              children: updatedChildren
            },
            destination: {
              id: sourceParent.id,
              children: updatedChildren
            },
            keyword: {
              id: moveNode.id,
              parentPath: ''
            }
          }

          updateKeyword(UpdatedObj)
          // moveKeywords(UpdatedObj).then(keyword => {
          //   setKeywords(keyword)
          //   // updateKeyword()
          // })
        }
      }

      if (closeHandler) {
        closeHandler()
      }
   } else {
      console.log('No children')
      const filename = activeNode.module

      const parentPath = moveNode.parentPath
      const child = activeNode.child

      let newParentPath = ""
      if (!activeNode.parentPath) {
        newParentPath =
          moveNode.module + " => " + moveNode.module + "," + activeNode.module
      }
      else if (activeNode.parentPath === "") {
        newParentPath =
          moveNode.module + " => " + moveNode.module + "," + activeNode.module
      } else {
        const index = activeNode.parentPath.indexOf("=>")
        const prefix = activeNode.parentPath.substr(0, index + 3)
        const suffix = activeNode.parentPath.substr(
          index + 3,
          activeNode.parentPath.length
        )

        newParentPath =
          activeNode.module + " => " + moveNode.module + ", " + suffix
      }

      const index = parentPath.indexOf("=>")
      const path = parentPath
        .substr(index + 2, parentPath.length - 1)
        .split(",")

      const parent = path[1].trim()

      const sourceParent = _.find(terms, {
        term: parent,
      })

      if (sourceParent.term !== activeNode.module) {
        const searchTerm = moveNode.module
        const childArray = sourceParent.children.split(",")
        const SourceParentChildren = _.remove(childArray, child => {
          return child !== searchTerm
        })
        const updatedChildren = SourceParentChildren.join()

        const newSource = {
          ...sourceParent,
          children: updatedChildren,
        }

        setSource(newSource)

        let newChild = ""
        let dest = ""

        if (activeNode === activeNode.child) {
          newChild = moveNode.module
        } else {
          dest = {
            ...activeNode,
            children: activeNode.child + "," + moveNode.module,
          }
        }

        setDestination(dest)

        const keywordId = {
          id: moveNode.id,
          parentPath: newParentPath,
        }

        setKeyword(keywordId)

        const updatedObj = {
          source: newSource,
          destination: dest,
          keyword: keywordId,
        }

        updateKeyword(updatedObj)
        // moveKeyword(updatedObj).then(keyword => {
        //   setKeywords(keyword)
        //   // updateKeyword()
        // })
      }

      if (closeHandler) {
        closeHandler()
      }
    }
  }

  const moveParentNode = () => {
    let newParentPath = ""


    if (!activeNode.parentPath) {
      newParentPath = activeNode.module + " => " + moveNode.module + "," + activeNode.module
    }
    else if (activeNode.parentPath === "") {
      newParentPath = activeNode.module + " => " + moveNode.module + "," + activeNode.module
    }
    else {
      const index = activeNode.parentPath.indexOf("=>")
      const suffix = activeNode.parentPath.substr(index + 3, activeNode.parentPath.length)
      newParentPath = activeNode.module + " => " + moveNode.module + "," + suffix 
    } 

    const updatedObj = {
      source: {
        id: activeNode.id,
        children: ''
      },
      destination: {
        id: activeNode.id,
        children: activeNode.child + "," + moveNode.module
      },
      keyword: {
        id: moveNode.id,
        parentPath: newParentPath
      }
    }

    updateKeyword(updatedObj)

    if (closeHandler) {
      closeHandler()
    }
  }

  const collect = props => {
    return props
  }

  const onItemClick = (nodeInfo) => {
    setActiveNode(nodeInfo)
    setModalOpen(true)
  } 

  const renderNode = nodeInfo => {
    return (
       <span
        onClick={() => onItemClick(nodeInfo)}
        >
        {nodeInfo.module}
      </span>

    )  
  }

  const onModalClose = () => {
    setModalOpen(false)
  }

  return (
    <>
      <br />
      <div className="searchbar">
        <SearchBar
          placeholder="Search for keywords"
          value={searched}
          onChange={searchVal => searchItems(searchVal)}
          onCancelSearch={() => cancelSearch()}
        />
      </div>
      <br />

      {modalOpen && 
        <ModalWindow title="Confirm Move" closeHandler={onModalClose}>
          {`Move ${moveNode.module} to ${activeNode.module}`}
          <div className={styles.action}>
            <div className={styles.buttonWrap}>
              <br />
              <Button
                id="save-company-data"
                label="Confirm"
                type="submit"
                btnStyle="primary"
                btnSize="small"
                fullWidth
                prependIcon="MdSave"
                onClick={confirmMove}
              />
            </div>
          </div>
        </ModalWindow>      
      }

      <Tree
        paddingLeft={20}
        tree={data}
        renderNode={renderNode}
      />
    </>
  )
}

export default MoveKeyword
