import React, { useState, useEffect } from "react"
import "./keyword-manager.module.scss"
import styled from "styled-components"
import Tree from "react-ui-tree"
import "react-ui-tree/dist/react-ui-tree.css"
import InnerLayout from "../layout/inner-layout/inner-layout"
import ModalWindow from "../../components/common/modal-window/modal-window"
import { MdAddBox, MdEdit, MdMoveToInbox, MdDelete } from "react-icons/md"
import AddKeywords from "../../components/keywords/keywords-add"
import EditKeywords from "../../components/keywords/keywords-rename"
import MoveKeyword from "../../components/keywords/keywords-move"
import modal from "../../services/modal"
import { Menu, Item, Separator, useContextMenu } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';
import { MdHelpOutline } from "react-icons/md"
import {
  getKeywords,
  deleteKeywords,
  updateKeywords,
  moveKeywords
} from "../../services/keyword"
import { getMediaByKeyword } from "../../services/media"
import _ from "lodash"
import SearchBar from "material-ui-search-bar"
import "react-notifications/lib/notifications.css"
import { union } from "lodash"
import styles from "./keyword-manager.module.scss"


const KeywordsManager = () => {
  const MENU_ID = 'CONTEXT_MENU_ID';
  const [keywords, setKeywords] = useState([])
  const [data, setData] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [moveModal, setMoveModal] = useState(false)
  const [editModal, setEditModal] = useState(null)
  const [searchInput, setSearchInput] = useState("")
  const [searched, setSearched] = useState("")
  const [node, setNode] = useState("")
  const [activeNode, setActiveNode] = useState("")
  const [terms, setTerms] = useState("")

  const searchItems = searchValue => {
    setSearchInput(searchValue)

    const keywords = data.children

    if (searchValue !== "") {
      const filteredData = keywords.filter(keyword => {
        return Object.values(keyword)
          .join("")
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      })

      let tree = {
        id: "root",
        module: "Keywords",
        children: filteredData,
      }
      setData(tree)
    } else {
      setData(data)
    }
  }

  useEffect(() => {
    getKeywords().then(keywords => {
      setTerms(keywords)
      createTree(keywords)
    })

  }, [])

  const createTree = (keywords) => {
    let newData = []

    const filtered = rootKeywords(keywords)

    _.map(filtered, node => {
      const result = createKeys(node, keywords)
      newData.push(result)
    })

    newData = _.sortBy(newData, ['module'])

    let tree = {
      id: "root",
      module: "Keywords",
      children: newData,
    }

    setData(tree)

  }

  const createSearchTree = (keywords) => {
    let newData = []

    const filtered = rootKeywords(keywords)

    _.map(filtered, node => {
      const result = createKeys(node, keywords)
      newData.push(result)
    })

    return newData;
  }


  const rootKeywords = (keywords) => {
    let parentPathExists = _.filter(keywords, {
      parentPath: "",
    })

    let parentPathMissing = _.filter(keywords, (keyword) => {
      return !_.has(keyword, 'parentPath')
    })

    const filtered = union(parentPathExists, parentPathMissing)

    return filtered
  }

  const createKeys = (node, keywords) => {
    let collapsed = true
    if (activeNode) {
      if (activeNode.parentPath) {
        if (activeNode.parentPath !== '') {

          if (activeNode.parentPath.includes(node.term)) {
            collapsed = false
          }
        }
        else {
          if (activeNode.module === node.term) {
            collapsed = false
          }
        }
      }
      else {
        if (activeNode.module === node.term) {
          collapsed = false
        }
      }
    }

    let json = {
      id: node.id,
      module: node.term,
      parentPath: node.parentPath,
      children: [],
      collapsed,
      child: node.children,
    }

    let result = ""
    if (node.children) {
      if (node.children !== "") {
        let children = node.children.split(",")
        _.map(children, child => {
          const newChild = _.find(keywords, {
            term: child,
          })
          if (newChild) {
            result = createKeys(newChild, keywords)
          }

          json.children.push(result)
        })

        json.children = _.sortBy(json.children, ['module'])
      }
    }

    return json
  }

  const cancelSearch = () => {
    getKeywords().then(keywords => {
      setTerms(keywords)
      createTree(keywords)
    })
  }

  const moveGetKeywords = (UpdatedObj) => {
    moveKeywords(UpdatedObj).then(keyword => {
      getKeywords().then(keywords => {
        setTerms(keywords)
        createTree(keywords)
      })
    })
  }

  const getUpdatedKeywords = () => {
    getKeywords().then(keywords => {
      setTerms(keywords)
      createTree(keywords)
    })
  }

  const getModalWindow = () => {
    const onModalClose = () => {
      setModalOpen(false)
    }
    if (modalOpen) {
      return (
        <ModalWindow title="Add Keyword" closeHandler={onModalClose}>
          <AddKeywords
            addNode={node}
            closeHandler={onModalClose}
            updateKeyword={getUpdatedKeywords}
          />
        </ModalWindow>
      )
    } else {
      return ""
    }
  }

  const getEditModal = () => {
    const onEditClose = () => {
      setEditModal(false)
      getUpdatedKeywords()
    }
    if (editModal) {
      return (
        <ModalWindow title="Rename Keyword" closeHandler={onEditClose}>
          <EditKeywords
            value={editModal}
            setEditModal={setEditModal}
            editNode={node}
            editTerms={terms}
            editKeyword={getUpdatedKeywords}
          />
        </ModalWindow>
      )
    }
  }

  const getMoveModal = () => {
    const onMoveClose = () => {
      setMoveModal(false)
      // getUpdatedKeywords()
    }
    if (moveModal) {
      return (
        <ModalWindow title="Move Keyword" closeHandler={onMoveClose}>
          <MoveKeyword
            moveNode={node}
            closeHandler={onMoveClose}
            updateKeyword={moveGetKeywords}
          />
        </ModalWindow>
      )
    }
  }

  const findKey = (keys, term) => {

    let module = '';
    if (keys.module === term) {
      module = keys
    }
    else {
      _.map(keys.children, node => {
        let result = findKey(node, term)
        if (result !== '') {
          module = result
        }
      })
    }


    return module
  }

  const generateKeys = keys => {
    let deleteKeys = ""
    let returnKeys = ""

    if (keys.child !== "") {
      _.map(keys.children, node => {
        if (deleteKeys === "") {
          deleteKeys = node.id
        } else {
          deleteKeys = deleteKeys + "," + node.id
        }
        const newKeys = generateKeys(node)
        if (node.id !== newKeys) {
          if (deleteKeys === "") {
            deleteKeys = newKeys
          } else {
            deleteKeys = deleteKeys + "," + newKeys
          }
        }
      })
      returnKeys = deleteKeys
    } else {
      returnKeys = keys.id
    }

    return returnKeys
  }

  const onDeleteConfirm = () => {
    const params = modal.getAlertModalParams()

    const id = params.id
    const sourceId = params.sourceId
    const nodeData = params.nodeData
    const parentChildren = params.parentChildren

    const nodeFound = findKey(data, nodeData.module)

    const keys = generateKeys(nodeFound)
    const childrenArray = keys.split(',')

    if (nodeData.child) {
      _.forEach(childrenArray, (id, key) => {
        deleteKeywords(id).then(keywords => {
          setKeywords(keywords)
        })
      })
    }

    if (parentChildren) {
      const updatedObj = {
        children: parentChildren
      }

      updateKeywords(id, updatedObj).then(keywords => {
        setKeywords(keywords)
      })
    }

    deleteKeywords(sourceId).then(keywords => {
      setKeywords(keywords)
      getKeywords().then(keywords => {
        setTerms(keywords)
        createTree(keywords)
      })
    })
  }

  const renderNode = nodeInfo => {
    return (
      <div className={styles.context}
        onContextMenu={displayMenu}
        onClick={displayMenu}
        id={nodeInfo.id}
        onmousedown="return false"
        module={nodeInfo.module}
        parentPath={nodeInfo.parentPath}
        child={nodeInfo.child}
      >
        {nodeInfo.module}
      </div>
    )
  }


  const { show } = useContextMenu({
    id: MENU_ID
  });

  const handleItemClick = ({ event, props, data }) => {
    const nodeInfo = {
      id: props.id,
      module: props.module,
      parentPath: props.parentPath,
      child: props.child
    }

    console.log('Props:', props)
    setActiveNode(nodeInfo)

    switch (event.currentTarget.id) {
      case "add":
        setModalOpen(true)
        setNode(nodeInfo)
        break;
      case "rename":
        if (nodeInfo.module !== "Keywords") {
          setNode(nodeInfo)
          setEditModal(true)
          setEditModal(nodeInfo.module)
        }
        break;
      case "move":
        if (nodeInfo.module !== "Keywords") {
          setMoveModal(true)
          const filename = nodeInfo.module
          setNode(nodeInfo)
        }
        break;
      case "delete":
        if (nodeInfo.module !== "Keywords") {
          setNode(nodeInfo)
          setTerms(terms)

          const node = nodeInfo

          if (!node.parentPath) {
            deleteParentNode(nodeInfo)
          } else if (node.parentPath === "") {
            deleteParentNode(nodeInfo)
          } else {
            setNode(nodeInfo)
            setTerms(terms)

            const index = nodeInfo.parentPath.indexOf("=>")
            const path = nodeInfo.parentPath.substr(index + 2, nodeInfo.parentPath.length - 1).split(",")
            const searchTerm = path[1].trim()

            const sourceParent = _.find(terms, {
              term: searchTerm,
            })

            let parentChildren = ''
            let keywordSearch = ',' + nodeInfo.module
            const parentIndex = sourceParent.children.indexOf(keywordSearch)
            if (index >= 0) {
              parentChildren = sourceParent.children.replace(keywordSearch, '')
            }
            else {
              keywordSearch = nodeInfo.module + ','
              parentChildren = sourceParent.children.replace(keywordSearch, '')
            }

            getMediaByKeyword(nodeInfo.module).then(media => {
              modal.setAlertModalOpen(true, {
                title: `(${nodeInfo.module}) has ${media.media} item(s) using this keyword`,
                content: "Do you want to permanently delete?",
                confirm: onDeleteConfirm,
                confirmLabel: "Yes",
                source: sourceParent,
                parentChildren,
                nodeData: node,
                id: sourceParent._id,
                sourceId: nodeInfo.id
              })
            })

          }
        }
        break;
      default:
    }
  }

  const deleteParentNode = (nodeInfo) => {
    getMediaByKeyword(nodeInfo.module).then(media => {
      console.log('Media:', media)
      modal.setAlertModalOpen(true, {
        title: `(${nodeInfo.module}) has ${media.media} item(s) using this keyword`,
        content: "Do you want to permanently delete?",
        confirm: onDeleteConfirm,
        confirmLabel: "Yes",
        nodeData: nodeInfo,
        sourceId: nodeInfo.id
      })
    })

  }

  const displayMenu = (e) => {

    show(e,
      {
        props:
        {
          id: e.currentTarget.id,
          module: e.currentTarget.getAttribute('module'),
          parentPath: e.currentTarget.getAttribute('parentPath'),
          child: e.currentTarget.getAttribute('child')
        }
      }
    );
  }

  const movedKeyword = (array1, array2, parent, originalArray) => {

    let found = false
    let diff = ''

    if (array2.length !== array1.length) {
      let largerArray = []
      let smallerArray = []
      if (array2.length > array1.length) {
        largerArray = array2
        smallerArray = array1
      }
      else {
        largerArray = array1
        smallerArray = array2
      }

      let movedKeyword = ''
      _.map(largerArray, (keyword1) => {
        const filteredItems = _.filter(smallerArray, (keyword2) => {
          return keyword1.module === keyword2.module
        })
        if (!filteredItems.length > 0) {
          movedKeyword = keyword1
        }
      })

      const keyword = findParent(originalArray, movedKeyword.module, 'Keywords')

      found = true
      diff = {
        ...movedKeyword,
        parent: keyword.parent
      }

    }
    else {
      let index = 0
      let result = ''

      while (!found && index < array1.length && index < array2.length) {
        if (array1[index].children && array2[index].children) {
          if (array1[index].children.length > 0 && array2[index].children.length > 0) {
            result = movedKeyword(array1[index].children, array2[index].children, array1[index].module, originalArray)
            if (result !== '') {
              diff = result
              found = true
            }
          }
        }
        index++
      }

    }

    return diff
  }

  const findParent = (keywords, module, parent) => {

    let found = false
    let keyword = ""
    let index = 0

    while (!found && index < keywords.length) {
      if (keywords[index].module === module) {
        found = true
        keyword = {
          parent
        }
      }
      else {
        if (keywords[index].children) {
          if (keywords[index].children.length > 0) {
            let result = findParent(keywords[index].children, module, keywords[index].module)
            if (result !== '') {
              keyword = result
              found = true
            }
          }
        }
      }
      index++
    }

    return keyword
  }


  const handleChange = tree => {

    let test = []
    getKeywords().then(keywords => {
      setTerms(keywords)
      const oldKeys = createSearchTree(keywords)

      console.log('OldKeys:', oldKeys)
      console.log('Tree:', tree.children)
      const nodeInfo = movedKeyword(oldKeys, tree.children, 'Keywords', tree.children)
      console.log('Node moved:', nodeInfo)

      if (nodeInfo !== '') {
        let newParentPath = ''
        let activeNode = ''

        activeNode = _.find(keywords, {
          term: nodeInfo.parent,
        })

        if (!nodeInfo.parentPath) {
          moveParentNode(activeNode, nodeInfo)
        }
        else if (nodeInfo.parentPath === '') {
          moveParentNode(activeNode, nodeInfo)
        }
        else if (!activeNode) {
          const parentPath = nodeInfo.parentPath

          const index = parentPath.indexOf("=>")
          const path = parentPath
            .substr(index + 2, parentPath.length - 1)
            .split(",")

          const parent = path[1].trim()

          const sourceParent = _.find(terms, {
            term: parent,
          })

          const searchTerm = nodeInfo.module
          const childArray = sourceParent.children.split(",")
          const sourceParentChildren = _.remove(childArray, child => {
            return child !== searchTerm
          })
          const updatedChildren = sourceParentChildren.join()

          const updatedObj = {
            source: {
              id: sourceParent.id,
              children: updatedChildren
            },
            destination: {
              id: sourceParent.id,
              children: updatedChildren
            },
            keyword: {
              id: nodeInfo.id,
              parentPath: ''
            }
          }

          moveGetKeywords(updatedObj)
        }
        else {
          const parentPath = nodeInfo.parentPath
          const child = activeNode.children

          let newParentPath = ""
          if (!activeNode.parentPath) {
            newParentPath =
              nodeInfo.module + " => " + nodeInfo.module + "," + activeNode.term
          }
          else if (activeNode.parentPath === "") {
            newParentPath =
              nodeInfo.module + " => " + nodeInfo.module + "," + activeNode.term
          } else {
            const index = activeNode.parentPath.indexOf("=>")
            const suffix = activeNode.parentPath.substr(
              index + 3,
              activeNode.parentPath.length
            )

            newParentPath =
              activeNode.term + " => " + nodeInfo.module + ", " + suffix
          }

          const index = parentPath.indexOf("=>")
          const path = parentPath
            .substr(index + 2, parentPath.length - 1)
            .split(",")

          const parent = path[1].trim()

          const source = _.find(terms, {
            term: parent,
          })

          if (source.term !== activeNode.term) {
            const searchTerm = nodeInfo.module
            const childArray = source.children.split(",")
            const sourceChildren = _.remove(childArray, child => {
              return child !== searchTerm
            })

            let updatedChildren = ''
            if (sourceChildren.length > 0) {
              updatedChildren = sourceChildren.join()
            }

            console.log('updatedChildren:', updatedChildren)

            const newSource = {
              ...source,
              children: updatedChildren,
            }

            let newChild = ""
            let dest = ""

            if (activeNode === activeNode.children) {
              newChild = nodeInfo.module
            } else {
              dest = {
                ...activeNode,
                children: activeNode.children + "," + nodeInfo.module,
              }
            }

            const keywordId = {
              id: nodeInfo.id,
              parentPath: newParentPath,
            }

            const updatedObj = {
              source: newSource,
              destination: dest,
              keyword: keywordId,
            }

            moveGetKeywords(updatedObj)
          }

        }
      }


    })




  }

  const moveParentNode = (activeNode, nodeInfo) => {
    let parentPath = ""


    if (!activeNode.parentPath) {
      parentPath = activeNode.term + " => " + nodeInfo.module + "," + activeNode.term
    }
    else if (activeNode.parentPath === "") {
      parentPath = activeNode.term + " => " + nodeInfo.module + "," + activeNode.term
    }
    else {
      const index = activeNode.parentPath.indexOf("=>")
      const suffix = activeNode.parentPath.substr(index + 3, activeNode.parentPath.length)
      parentPath = activeNode.term + " => " + nodeInfo.module + "," + suffix
    }

    const updatedObj = {
      source: {
        id: activeNode.id,
        children: ''
      },
      destination: {
        id: activeNode.id,
        children: activeNode.children + "," + nodeInfo.module
      },
      keyword: {
        id: nodeInfo.id,
        parentPath: parentPath
      }
    }

    moveGetKeywords(updatedObj)
  }


  return (
    <InnerLayout>
      <div class="tooltip">
        Keywords Manager
        <span
          className="helpIcon"
          data-tip="Click on keywords to get Actions."
          data-event="click focus"
        >
          <a onClick={e => e.preventDefault()}>
            <MdHelpOutline />
          </a>
        </span>
      </div>
      <br />
      <br />
      <div className={styles.searchbar}>
        <SearchBar
          placeholder="Search for keywords"
          value={searched}
          onChange={searchValue => searchItems(searchValue)}
          onCancelSearch={() => cancelSearch()}
        />
      </div>
      <br />
      <Container>
        <Tree
          paddingLeft={20}
          tree={data}
          onChange={handleChange}
          renderNode={renderNode}
        />
      </Container>

      {getModalWindow()}
      {getEditModal()}
      {getMoveModal()}
      <Menu id={MENU_ID}>
        <Item id='add' onClick={handleItemClick}>
          <MdAddBox /> Add Keyword
        </Item>
        <Separator />
        <Item id='rename' onClick={handleItemClick}>
          <MdEdit /> Rename Keyword
        </Item>
        <Separator />
        <Item id='move' onClick={handleItemClick}>
          <MdMoveToInbox /> Move Keyword
        </Item>
        <Separator />
        <Item id='delete' onClick={handleItemClick}>
          <MdDelete /> Delete Keyword
        </Item>
      </Menu>
    </InnerLayout>
  )
}

const Container = styled.div`
  border-right: 1px solid #ccc;
  height: 50vh;
  overflow: auto;
  width: 900px;
`

export default KeywordsManager
