import "./index.scss"
import _ from "lodash"
import React, { useState, useEffect } from "react"
import { useForm } from "react-form"
import { updateKeywords, renameKeyword } from "../../../services/keyword"
import Button from "../../common/button/button"
import TextField from "../../common/form/text-field/text-field"
import { MdError, MdCheck } from "react-icons/md"
import styles from "../../common/button/button.module.scss"

const EditKeywords = ({
  value,
  editNode,
  editTerms,
  setEditModal,
  editKeyword,
}) => {
  const [submitAttempted, setSubmitAttempted] = useState()
  const [keywords, setKeywords] = useState([])
  const [node, setNode] = useState(editNode)
  const [showMessage, setShowMessage] = useState(false)
  const [error, setError] = useState()
  const [success, setSuccess] = useState()
  const [data, setData] = useState(data)
  const [terms, setTerms] = useState(editTerms)
  const [source, setSource] = useState()
  const [selectedData, setSelectedData] = useState({
    id: null,
    term: "",
  })

  const editChildren = editNode.children
  const handleOnChange = (dataKey, val) => {
    setSelectedData({
      ...selectedData,
      [dataKey]: val,
    })
  }

  const formInstance = useForm({
    debugForm: false,
    onSubmit: values => {
      if (!editNode.parentPath) {
        renameParentKeywords(values)
      } else if (editNode.parentPath === "") {
        renameParentKeywords(values)
      } else if (editNode.child !== "") {
        console.log('editNode:', editNode.child)
        const id = node.id
        const path = node.parentPath
        const index = node.parentPath.indexOf("=>")
        const parentPath = node.parentPath.substr(0, index - 1)
        // const editChildren = editNode.children
        let searchTerm = ''
        let newParentPath = ''
        let suffixArray = []
        if (parentPath === node.module) {
          newParentPath = values.term
          const suffix = node.parentPath.substr(index + 3,  node.parentPath.length)
          suffixArray = suffix.split(',')
          searchTerm = suffixArray[1].trim()
        }
        else {
          newParentPath = parentPath
          searchTerm = parentPath
        }

        console.log('searchTerm', searchTerm)
        console.log('searchTerm length', searchTerm.length)
        const sourceParent = _.find(terms, {
          term: searchTerm,
        })

        console.log('sourceParent', sourceParent)
        const term = values.term
        let children = ""
        const editChild = {
          children: sourceParent.children.replace(node.module, values.term),
        }

        const Id = sourceParent.id
        updateKeywords(Id, editChild).then(keywords => {
          setKeywords(keywords)
        })

        const updatedObj = {
          id: node.id,
          seedId: "keyword_" + values.term,
          term: values.term,
          parentPath:
            newParentPath + " " + "=>" + " " + values.term + "," + searchTerm,

          ...values,
        }

        renameKeyword(updatedObj).then(keyword => {
          setKeywords(keyword)
          editKeyword()
        })

        let mappedArray = []

        const editChildren = editNode.child.split(',')
        console.log('Test:', editChildren)
        _.map(editChildren, child => {
          const childInfo = _.find(terms, {
            term: child,
          })

          if (childInfo) {
            console.log('childInfo',childInfo)

            if (childInfo) {
              const index = childInfo.parentPath.indexOf("=>")

              let suffix = childInfo.parentPath
                .substr(index + 3, childInfo.parentPath.length)
                .split(",")

              suffix[1] = values.term
              const parentPath = values.term + " " + "=>" + " " + suffix.join()
              console.log('parentPath', parentPath)
              mappedArray.push({
                id: childInfo._id,
                parentPath,
              })
            }
          }    
        })

        _.forEach(mappedArray, function(value, key) {
          const childUpdatedObj = {
            parentPath: value.parentPath,
          }
          console.log('childUpdatedObj:', childUpdatedObj)
          updateKeywords(value.id, childUpdatedObj).then(keywords => {
            setKeywords(keywords)
          })
        })
        setEditModal(false)
      } else {
        const id = node.id
        const path = node.parentPath
        const index = node.parentPath.indexOf("=>")
        const parentPath = node.parentPath.substr(0, index - 1)
        const editChildren = editNode.children

        let searchTerm = ''
        if (parentPath === node.module) {
          const suffix = node.parentPath.substr(index + 3,  node.parentPath.length)
          const suffixArray = suffix.split(',').trim
          searchTerm = suffixArray[1]
        }
        else {
          searchTerm = parentPath
        } 

        console.log('searchTerm', searchTerm)
        const sourceParent = _.find(terms, {
          term: searchTerm,
        })

        const term = values.term

        let children = ""
        const editChild = {
          children: sourceParent.children.replace(node.module,values.term)
        }

        const Id = sourceParent.id
        updateKeywords(Id, editChild).then(keywords => {
          setKeywords(keywords)
        })
        const updatedObj = {
          id: node.id,
          seedId: "keyword_" + values.term,
          term: values.term,
          parentPath:
            parentPath + " " + "=>" + " " + values.term + "," + parentPath,

          ...values,
        }

        renameKeyword(updatedObj).then(keyword => {
          setKeywords(keyword)
          editKeyword()
        })
        setEditModal(null)
      }
    },

    validate: (values, instance) => {
      setShowMessage(false)
      setSubmitAttempted(false)

      if (!values.term) {
        return true //  "name is required"
      }
    },
  })

  const renameParentKeywords = (values) => {
    const updatedObj = {
      id: node.id,
      seedId: "keyword_" + values.term,
      term: values.term,
      parentPath: '',
      ...values,
    }

    renameKeyword(updatedObj).then(keyword => {
      setKeywords(keyword)
      editKeyword()
    })

    let mappedArray = []

    if (editNode.child !== null) {
      const editChildren = editNode.child.split(',')
      _.map(editChildren, child => {
        const childInfo = _.find(terms, {
          term: child,
        })

        if (childInfo) {
          const index = childInfo.parentPath.indexOf("=>")
          let suffix = childInfo.parentPath
            .substr(index + 3, childInfo.parentPath.length)
            .split(",")

          suffix[1] = values.term
          const parentPath = values.term + " " + "=>" + " " + suffix.join()
          mappedArray.push({
            id: childInfo._id,
            parentPath,
          })
        }
      })
    }  

    _.forEach(mappedArray, function(value, key) {
      const childUpdatedObj = {
        parentPath: value.parentPath,
      }
      updateKeywords(value.id, childUpdatedObj).then(keywords => {
        setKeywords(keywords)
      })
    })

    setEditModal(null)
  }

  const getRenameError = () => {
    if (showMessage) {
      if (error) {
        return (
          <div>
            <MdError /> {error}
          </div>
        )
      } else {
        return (
          <div>
            <MdCheck /> {success}
          </div>
        )
      }
    }
  }

  const { Form, meta, pushFieldValue } = formInstance

  return (
    <>
      <div>
        <Form>
          <div>
            <div className="textarea">
              <TextField
                style={{ width: "450px" }}
                label="Keyword"
                field="term"
                defaultValue={value}
                onChange={e => handleOnChange("term", e.target.value)}
                validate={value => {
                  return !value ? "Please Enter a Valid Term!" : false
                }}
                mandatory
              />
              <div className={styles.action}>
                <div className={styles.buttonWrap}>
                  <br />
                  <Button
                    style={{ width: "450px" }}
                    id="save-company-data"
                    label="Save"
                    type="submit"
                    btnStyle="primary"
                    btnSize="small"
                    fullWidth
                    prependIcon="MdSave"
                  />
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  )
}

export default EditKeywords
